import { FC } from "react"

import {
  baseContainerEllipseFilter,
  baseContainerEllipseSmallA,
  baseContainerEllipseSmallB,
  baseContainerEllipseSmallC,
} from "./BackgroundEllipse.css"

export const BackgroundEllipse: FC<{ className: string }> = ({ className }) => (
  <div className={className}>
    <div className={baseContainerEllipseFilter} />
    <div className={baseContainerEllipseSmallA} />
    <div className={baseContainerEllipseSmallB} />
    <div className={baseContainerEllipseSmallC} />
  </div>
)
