import { Field } from "formik"
import { FC, useState } from "react"

import email from "../../assets/email.svg"
import eye from "../../assets/eye.svg"
import password from "../../assets/password.svg"
import { Button } from "../Button"
import { customButtonContainer } from "../Button/Button.css"
import {
  baseFormikField,
  baseFormikFieldContainer,
  baseFormikFieldIcon,
  baseFormikFieldPlaceholder,
} from "./FormikField.css"

export const FormikField: FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    classNameContainer?: string
    classNameLabel?: string
    label?: string
    hideIcon?: boolean
  }
> = ({ classNameContainer, classNameLabel, hideIcon, label, ...props }) => {
  const isPassword = props.name === "password" && !hideIcon
  const isEmail = props.name === "email" && !hideIcon
  const [type, setType] = useState(props.type)
  return (
    <div className={classNameContainer ?? baseFormikFieldContainer}>
      <div className={classNameLabel ?? baseFormikFieldPlaceholder}>{label ?? props.name}</div>
      {isPassword || isEmail ? (
        <img
          alt={props.name}
          src={isPassword ? password : email}
          className={baseFormikFieldIcon}
        />
      ) : null}
      {isPassword ? (
        <Button
          className={customButtonContainer.formikFieldEye}
          onClick={() => {
            if (type === "text") {
              setType("password")
            } else {
              setType("text")
            }
          }}
        >
          <img alt={"eye"} src={eye} />
        </Button>
      ) : null}
      <Field className={baseFormikField} {...props} type={type} />
    </div>
  )
}
