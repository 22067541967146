import { Provider } from "react-redux"
import { RouterProvider } from "react-router"

import { store } from "./api/store"
import { Notifications } from "./components/Notification"
import { router } from "./routes"

const App = () => (
  <Provider store={store}>
    <Notifications />
    <RouterProvider router={router} />
  </Provider>
)

export default App
