import { FC } from "react"

import {
  customLoadingSpinnerBig,
  customLoadingSpinnerSmall,
  loadingSpinner,
  loadingSpinnerSmall,
} from "./Loading.css"

export type BaseLoadingProps = {
  size?: "small" | "big"
  color?: "black" | "white"
}

export const Loading: FC<BaseLoadingProps> = ({ size = "big", color = "black" }) => {
  const isSmall = size === "small"
  return (
    <div className={isSmall ? loadingSpinnerSmall : loadingSpinner}>
      <div
        className={
          isSmall
            ? customLoadingSpinnerSmall[color]
            : customLoadingSpinnerBig[color]
        }
      />
    </div>
  )
}
