import { AnyAction, configureStore, Dispatch, isRejectedWithValue } from "@reduxjs/toolkit"

import { TTFError } from "../types/types"
import { deleteCookieByName } from "../utils/session"
import { authApi, passwordRestoreApi } from "./auth"
import { brandsApi } from "./brands"
import { customersApi } from "./customers"
import { disputesApi } from "./disputes"
import { internalEventsApi } from "./events"
import { incomingInquiriesApi } from "./inquiries"
import { ordersApi, ordersApiConsumer } from "./orders"
import { promoterDiscountLinksApi } from "./promoterDiscountLinks"
import { sessionsApi } from "./sessions"
import { ticketsApi } from "./ticket"
import { venuesApi } from "./venues"

export const errorMiddleware = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  if (isRejectedWithValue(action)) {
    if (action.payload && action.meta.arg) {
      const payload = action.payload as TTFError
      const arg = action.meta.arg as { endpointName?: string }
      if (arg.endpointName !== "getSession" && (payload.status === 401 || payload.status === 403)) {
        deleteCookieByName("X-TF-ECOMMERCE")
        window.location.href = "/login"
      }
    }
  }

  return next(action)
}

export const store = configureStore({
  reducer: {
    [internalEventsApi.reducerPath]: internalEventsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [passwordRestoreApi.reducerPath]: passwordRestoreApi.reducer,
    [brandsApi.reducerPath]: brandsApi.reducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [disputesApi.reducerPath]: disputesApi.reducer,
    [sessionsApi.reducerPath]: sessionsApi.reducer,
    [incomingInquiriesApi.reducerPath]: incomingInquiriesApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [ordersApiConsumer.reducerPath]: ordersApiConsumer.reducer,
    [venuesApi.reducerPath]: venuesApi.reducer,
    [promoterDiscountLinksApi.reducerPath]: promoterDiscountLinksApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      passwordRestoreApi.middleware,
      internalEventsApi.middleware,
      brandsApi.middleware,
      ticketsApi.middleware,
      disputesApi.middleware,
      sessionsApi.middleware,
      incomingInquiriesApi.middleware,
      customersApi.middleware,
      ordersApi.middleware,
      ordersApiConsumer.middleware,
      venuesApi.middleware,
      promoterDiscountLinksApi.middleware,
      errorMiddleware,
    ]),
})
