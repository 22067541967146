import { FC, ReactNode } from "react"
import { Link } from "react-router-dom"

import { customButtonContainer } from "../Button/Button.css"

export const HeaderLink: FC<{ children: ReactNode; to: string }> = ({
  children,
  to,
}) => (
  <Link to={to} className={customButtonContainer.containerHeaderLink}>
    {children}
  </Link>
)
