import { FC, ReactNode } from "react"

import ttfLogo from "../../assets/ttf-logo-only.png"
import ttftext from "../../assets/ttf-text-only.png"
import {
  baseBoxContainerHeader,
  baseButtonsContainerHeader,
  baseContainerHeader,
  baseContainerLineHeader,
  baseImageContainerHeader,
  baseImageLogoHeader,
  baseImageTextHeader,
} from "./Header.css"

export const Header: FC<{ children: ReactNode }> = ({ children }) => (
  <div className={baseContainerHeader}>
    <div className={baseContainerLineHeader} />
    <div className={baseBoxContainerHeader}>
      <div className={baseImageContainerHeader}>
        <img className={baseImageLogoHeader} src={ttfLogo} alt="logo" />
        <img className={baseImageTextHeader} src={ttftext} alt="logo" />
      </div>
      <div className={baseButtonsContainerHeader}>{children}</div>
    </div>
  </div>
)
