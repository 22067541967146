import { FC, ReactNode } from "react"
import { Link } from "react-router-dom"

import { baseForgotPasswordTextContainer } from "./ForgotPasswordText.css"

export const RedirectTextText: FC<{ children: ReactNode; to: string }> = ({
  children,
  to,
}) => (
  <div>
    <Link to={to} className={baseForgotPasswordTextContainer}>
      {children}
    </Link>
  </div>
)
