import { FC } from "react"

import { BaseLoadingProps, Loading } from "../Loading"
import { customButtonContainer } from "./Button.css"

type BaseButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  isLoading?: boolean
} & BaseLoadingProps

export const Button: FC<BaseButtonProps> = ({ isLoading, size, color, children, ...props }) => (
  <button className={customButtonContainer.ttf} type="button" {...props} >
    {isLoading ? <Loading size={size} color={color} /> : children}
  </button>
)
