import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"

import { SessionsResponse } from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

const baseQuery = fetchBaseQuery({
    baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
    credentials: "include",
    headers: {
        "Accept": "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
    },
})
const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)
    if (result.error && typeof args !== "string" && api.endpoint === "getSessions") {
        return {
            meta: result.meta,
            data: {
                data: {
                    user: {
                        email: "",
                        user_id: "",
                        sessions: []
                    },
                }
            }
        }
    }
    return result
}

export const sessionsApi = createApi({
    reducerPath: "sessionsApi",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Sessions"],
    endpoints: (build) => ({
        getSessions: build.query<
            SessionsResponse,
            {
                email: string
            }
        >({
            query: ({ email }) => ({
                url: "/sessions?" +
                    new URLSearchParams({
                        email,
                    })
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when searching sessions!", response)
        }),
        removeSession: build.mutation<
            void,
            {
                email: string
                sessionId: string
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when removing session!", response),
            query: ({ sessionId, email }) => ({
                url: `/session`,
                method: "DELETE",
                body: {
                    data: {
                        attributes: {
                            session_id: sessionId,
                            email,
                        },
                    },
                },
            }),
            async onQueryStarted({ email, sessionId }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    sessionsApi.util.updateQueryData(
                        "getSessions",
                        { email },
                        (draft) => {
                            const index = draft.data.user.sessions.findIndex((session) => session.id === sessionId)
                            if (index !== -1) {
                                draft.data.user.sessions.splice(index, 1)
                            }
                        }
                    )
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
    }),
})

export const {
    useLazyGetSessionsQuery,
    useRemoveSessionMutation,
    useGetSessionsQuery
} = sessionsApi