import { Formik } from "formik"

import { useLogInMutation } from "../../api/auth"
import { AuthFooterText } from "../../components/AuthFooterText"
import { AuthForm } from "../../components/AuthForm"
import { AuthSubtitle } from "../../components/AuthSubtitle"
import { AuthTitle } from "../../components/AuthTitle"
import { BackgroundEllipse } from "../../components/BackgroundEllipse"
import { customContainerEllipse } from "../../components/BackgroundEllipse/BackgroundEllipse.css"
import { Button } from "../../components/Button"
import { RedirectTextText } from "../../components/ForgotPasswordText"
import { FormikField } from "../../components/FormikField"
import { showErrorToast } from "../../components/Notification"
import { baseScreenContainer } from "../Header/Header.css"
import { baseHomeContainer } from "./Home.css"

export const catchError = (error: unknown) => {
  if (typeof error === 'string') {
    return showErrorToast(error)
  }
  if (error instanceof Error) {
    return showErrorToast(error.message)
  }
  return showErrorToast("Error!")
}

const Home = () => {
  const [logIn, { isLoading }] = useLogInMutation()
  return (
    <div className={baseHomeContainer}>
      <BackgroundEllipse className={customContainerEllipse.containerEllipseA} />
      <BackgroundEllipse className={customContainerEllipse.containerEllipseB} />
      <div className={baseScreenContainer}>
        <AuthTitle>Internal Admin</AuthTitle>
        <AuthSubtitle>Log in as Admin</AuthSubtitle>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async (values) => {
            try {
              const response = await logIn(values).unwrap()
              if (
                !([
                  "ttf_team",
                  "tf_developer",
                  "tf_support_admin",
                  "tf_client_support_admin"
                ].includes(response.data.tfRole))
              ) {
                throw "Not enough permission"
              }
              window.location.href = "/"
            } catch (e) {
              catchError(e)
            }
          }}
        >
          {({ values: { email, password } }) => (
            <AuthForm>
              <FormikField name="email" type="email" value={email} />
              <FormikField name="password" type="password" value={password} />
              <Button type="submit" disabled={isLoading} isLoading={isLoading} size="small" color="white">
                Log In
              </Button>
              <RedirectTextText to={"/reset-password"}>Forgot your password?</RedirectTextText>
            </AuthForm>
          )}
        </Formik>
        <AuthFooterText>
          © 2050 THETICKETFAIRY All Rights Reserved
        </AuthFooterText>
      </div>
    </div>
  )
}

export default Home
