import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import {
  EditIncomingInquiryBusinessResponse,
  EditIncomingInquiryResponse,
  IncomingInquiriesResponse, InquiryHistoryRequest,
} from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

export const incomingInquiriesApi = createApi({
  reducerPath: "incomingInquiriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
    credentials: "include",
    headers: {
      "Accept": "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
    },
  }),
  tagTypes: ["IncomingInquiries", "EditIconmingEnquiry", "IncomingInquiryHistory"],
  endpoints: (build) => ({
    getIncomingInquiryHistory: build.query<
      InquiryHistoryRequest,
      { id: string }
    >({
      query: ({ id }) => ({
        url:
          `/incoming-inquiry-history/${id}`,
      }),
      providesTags: (_result, _error, { id }) => [
        { type: "IncomingInquiryHistory", id },
      ],
    }),
    getIncomingInquiries: build.query<
      IncomingInquiriesResponse,
      { search: string; page: number; limit: number; mine: boolean }
    >({
      transformResponse: (response: IncomingInquiriesResponse): IncomingInquiriesResponse => {
        response.data.members = response.data.members.map(member => {
          const splitLabel = member.label.replace(")", "").split(" (")
          const [name, email] = splitLabel
          return ({ value: member.value, label: `${name}<br><strong>${email}<strong>` })
        })
        response.data.members.unshift({ value: "0", label: "Unassigned" })
        return response
      },
      query: ({ search, page, limit, mine }) => ({
        url:
          "/incoming-inquiries?" +
          new URLSearchParams({
            search,
            page: String(page),
            limit: String(limit),
            mine: String(mine),
          }),
      }),
      providesTags: (_result, _error, { search, page, limit, mine }) => [
        { type: "IncomingInquiries", search, page, limit, mine },
      ],
    }),
    updateIncomingInquiry: build.mutation<
      EditIncomingInquiryBusinessResponse,
      { id: string; member_id: string; search: string; page: number; limit: number; mine: boolean }
    >({
      query: ({ id, member_id }) => ({
        method: "PATCH",
        url: `/incoming-inquiry/${id}`,
        body: {
          data: {
            attributes: {
              member_id
            },
          },
        },
      }),
      transformErrorResponse: (response) => processErrorResponse("Error when editing inquiry!", response),
      async onQueryStarted({ id, search, page, limit, mine, member_id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          incomingInquiriesApi.util.updateQueryData(
            "getIncomingInquiries",
            { search, page, limit, mine },
            (draft) => {
              const index = draft.data.inquiries.findIndex(
                (inquiry) => inquiry.id === id
              )
              if (index !== -1) {
                draft.data.inquiries[index].assignedToCustomerId = member_id
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    updateStatusIncomingInquiry: build.mutation<
      EditIncomingInquiryResponse,
      { id: string; status_id: string; notes: string; search: string; page: number; limit: number; mine: boolean }
    >({
      query: ({ id, status_id, notes }) => ({
        method: "PATCH",
        url: `/incoming-inquiry/status/${id}`,
        body: {
          data: {
            attributes: {
              status: status_id,
              notes,
            },
          },
        },
      }),
      transformErrorResponse: (response) => processErrorResponse("Error when editing inquiry!", response),
      async onQueryStarted({ id, search, page, limit, mine, status_id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          incomingInquiriesApi.util.updateQueryData(
            "getIncomingInquiries",
            { search, page, limit, mine },
            (draft) => {
              const index = draft.data.inquiries.findIndex(
                (inquiry) => inquiry.id === id
              )
              if (index !== -1) {
                draft.data.inquiries[index].status = status_id
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
})

export const {
  useGetIncomingInquiriesQuery,
  useUpdateIncomingInquiryMutation,
  useLazyGetIncomingInquiryHistoryQuery,
  useUpdateStatusIncomingInquiryMutation
} = incomingInquiriesApi