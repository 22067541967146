import { isRouteErrorResponse, useRouteError } from "react-router-dom"

import { baseErrorPageContainer } from "./ErrorPage.css"

export const ErrorPage = () => {
  const error = useRouteError()
  return (
    <div className={baseErrorPageContainer}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>
          {isRouteErrorResponse(error)
            ? error.statusText
            : error instanceof Error
            ? error.message
            : ""}
        </i>
      </p>
    </div>
  )
}
