import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"

import {
    GetTicketResponse,
} from "../types/types"
import { ENV } from "../utils/env"
import { processErrorResponse } from "./auth"

const baseQuery = fetchBaseQuery({
    baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
    credentials: "include",
    headers: {
        "Accept": "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
    },
})
const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)
    if (result.error && typeof args !== "string" && api.endpoint === "getTicket") {
        return {
            meta: result.meta,
            data: {
                data: {
                    ticket: null,
                    ticket_type_name: '',
                    countries: [],
                }
            }
        }
    }
    return result
}

export const ticketsApi = createApi({
    reducerPath: "ticketsApi",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Ticket"],
    endpoints: (build) => ({
        getTicket: build.query<
            GetTicketResponse,
            {
                hash: string
            }
        >({
            query: ({ hash }) => ({
                url: "/ticket/" + hash
            }),
            providesTags: (_result, _error, { hash }) => [
                { type: "Ticket", hash },
            ],
            transformResponse: (response: GetTicketResponse): GetTicketResponse => {
                response.data.countries.unshift({ value: "", label: "Select" })
                return response
            },
            transformErrorResponse: (response) => processErrorResponse("Error when searching ticket!", response)
        }),
        swapTickets: build.mutation<
            void,
            {
                id: string
                email: string
                hash: string
                first_name?: string
                last_name?: string
                country_id?: string
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when swapping tickets!", response),
            query: ({ id, email, first_name, last_name, country_id }) => ({
                url: `/ticket/${id}`,
                method: "PATCH",
                body: {
                    data: {
                        attributes: {
                            email,
                            first_name,
                            last_name,
                            country_id,
                        },
                    },
                },
            }),
            async onQueryStarted({ hash }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(
                        ticketsApi.util.updateQueryData(
                            "getTicket",
                            { hash },
                            (draft) => {
                                if (draft.data.ticket) {
                                    draft.data.ticket.status = "cancelled"
                                }
                            }
                        )
                    )
                } catch { }
            },
        }),
    }),
})

export const {
    useGetTicketQuery,
    useSwapTicketsMutation
} = ticketsApi
