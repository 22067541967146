export const getDomain = (url: string) => {
    let updatedUrl = url.replace(/(https?:\/\/)?(www.)?/i, '')
    const updatedUrlArray = updatedUrl.split('.')
    updatedUrl = updatedUrlArray.slice(1).join('.')
    if (updatedUrl.indexOf('/') !== -1) {
        return updatedUrl.split('/')[0]
    }
    return updatedUrl
}

export const setCustomCookie = (name: string, value: string, days = 5) => {
    let expires = ''
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toUTCString()
    }
    const domain = getDomain(window.location.hostname)
    document.cookie = name + '=' + (value || '') + expires + `; path=/;domain=${domain}`
}

export const getCookieByName = (cname: string) => {
    const name = cname + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

export const deleteCookieByName = (name: string) => {
    const domain = getDomain(window.location.hostname)
    document.cookie = name + `=; Path=/;domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}
