import { FC, useEffect, useState } from "react"
import { createPortal } from "react-dom"

import { ReactComponent as WarningSVG } from "../../assets/warning.svg"
import { notificationType } from "../../types/types"
import { Button } from "../Button"
import { customButtonContainer } from "../Button/Button.css"
import { baseNotificationsContainer, warningIconError, warningIconSuccess } from "./Notification.css"

const Notification: FC<{
  text: string
  type: notificationType
  timeout?: number
  timeStamp: string
}> = ({ text, type, timeStamp, timeout = 20000 }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const event = new CustomEvent("deleteNotification", {
        detail: { id: timeStamp },
      })
      document.dispatchEvent(event)
    }, timeout)
    return () => {
      clearTimeout(timer)
    }
  }, [timeout, text, type, timeStamp])
  const isError = type === "error"
  return (
    <Button
      className={isError ? customButtonContainer.notificationContainerError : customButtonContainer.notificationContainerSuccess}
      onClick={() => {
        const event = new CustomEvent("deleteNotification", {
          detail: { id: timeStamp },
        })
        document.dispatchEvent(event)
      }}
    >
      {isError ? <WarningSVG className={warningIconError} /> : <WarningSVG className={warningIconSuccess} />}
      {text}
    </Button>
  )
}

export const Notifications = () => {
  const [notifications, setNotifications] = useState<
    { type: notificationType; text: string; id: string; timeout?: number }[]
  >([])
  useEffect(() => {
    function listener(event: unknown) {
      if (event instanceof CustomEvent) {
        setNotifications((notifications) => [
          ...notifications,
          {
            type: event.detail.type,
            text: event.detail.text,
            id: String(event.timeStamp),
          },
        ])
      }
    }
    document.addEventListener("newNotification", listener)
    return () => {
      document.removeEventListener("newNotification", listener)
    }
  }, [])
  useEffect(() => {
    function listener(event: unknown) {
      if (event instanceof CustomEvent) {
        setNotifications((notifications) =>
          notifications.filter(
            (notification) => notification.id !== event.detail.id
          )
        )
      }
    }
    document.addEventListener("deleteNotification", listener)
    return () => {
      document.removeEventListener("deleteNotification", listener)
    }
  }, [])
  const node = document.getElementById("notifications")
  return node
    ? createPortal(
      <div className={baseNotificationsContainer}>
        {notifications.map(({ text, type, id, timeout }) => (
          <Notification
            key={id}
            text={text}
            type={type}
            timeStamp={id}
            timeout={timeout}
          />
        ))}
      </div>,
      node
    )
    : null
}

export const showSuccessToast = (text: string, timeout?: number) => {
  const event = new CustomEvent("newNotification", {
    detail: {
      text,
      type: "success",
      timeout,
    },
  })
  document.dispatchEvent(event)
}

export const showErrorToast = (text: string, timeout?: number) => {
  const event = new CustomEvent("newNotification", {
    detail: {
      text,
      type: "error",
      timeout,
    },
  })
  document.dispatchEvent(event)
}