import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"
import dayjs from "dayjs"

import { AddNoteOrderResponse, RefundTicketOrAddonResponse, SupportToolsResponse } from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

const baseQuery = fetchBaseQuery({
    baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
    credentials: "include",
    headers: {
        "Accept": "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
    },
})

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)
    if (result.error && typeof args !== "string" && api.endpoint === "getOrders") {
        return {
            meta: result.meta,
            data: {
                data: {
                    orders: [],
                }
            }
        }
    }
    return result
}

export const ordersApi = createApi({
    reducerPath: "ordersApi",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Orders"],
    endpoints: (build) => ({
        getOrders: build.query<
            SupportToolsResponse,
            {
                order_id?: string
                ticket_hash?: string
                email?: string
            }
        >({
            query: ({ order_id, ticket_hash, email }) => ({
                url: "/support-tools-orders?" + new URLSearchParams({
                    order_id: order_id ?? "",
                    ticket_hash: ticket_hash ?? "",
                    email: email ?? "",
                })
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when searching ticket!", response),
            providesTags: (_result, _error, { order_id, ticket_hash, email }) =>
                [{
                    type: "Orders",
                    order_id: order_id || "",
                    ticket_hash: ticket_hash || "",
                    email: email || "",
                }]
        }),
        resendOrder: build.mutation<
            string,
            { order_id: string }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when sending email order!", response),
            query: ({ order_id }) => ({
                method: "POST",
                url: `/resend-order/${order_id}`,
            }),
        }),
        refundOrder: build.mutation<
            string,
            {
                order_id: string
                order_hash: string
                refund_full_amount: boolean
                refund_application_fee: boolean
                order_id_search: string
                ticket_hash_search: string
                email_search: string
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when sending email order!", response),
            query: ({ order_id, order_hash, refund_full_amount, refund_application_fee }) => ({
                method: "POST",
                url: `/refund-order/${order_id}`,
                body: {
                    data: {
                        attributes: {
                            order_hash,
                            refund_full_amount,
                            refund_application_fee,
                        }
                    }
                }
            }),
            async onQueryStarted({
                order_id,
                order_id_search,
                ticket_hash_search,
                email_search
            }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    const response: SupportToolsResponse = await (await fetch(
                        `${ENV.BUSINESS_CONSUMER}/api/support-tools-orders?order_id=${order_id}`,
                        {
                            method: "GET",
                            credentials: 'include',
                            headers: {
                                "Accept": "application/vnd.api+json",
                                "Content-Type": "application/vnd.api+json",
                                'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
                            }
                        }
                    )).json()
                    dispatch(
                        ordersApi.util.updateQueryData(
                            "getOrders",
                            { email: email_search, order_id: order_id_search, ticket_hash: ticket_hash_search },
                            (draft) => {
                                const indexOrder = draft.data.orders.findIndex(
                                    (order) => order.id === order_id
                                )
                                if (indexOrder !== -1) {
                                    const [order] = response.data.orders
                                    draft.data.orders[indexOrder] = order
                                }
                            }
                        )
                    )
                } catch { }
            },
        }),
        editTicket: build.mutation<
            string,
            {
                id: string
                hash: string
                first_name: string
                last_name: string
                email: string
                order_id_search: string
                ticket_hash_search: string
                email_search: string
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error editing ticket!", response),
            query: ({ id, hash, first_name, last_name, email }) => ({
                method: "POST",
                url: `/ticket/edit/${id}/${hash}`,
                body: {
                    data: {
                        attributes: {
                            first_name,
                            last_name,
                            email,
                        }
                    }
                }
            }),
            async onQueryStarted({
                id,
                first_name,
                last_name,
                email,
                order_id_search,
                ticket_hash_search,
                email_search
            }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    ordersApi.util.updateQueryData(
                        "getOrders",
                        { email: email_search, order_id: order_id_search, ticket_hash: ticket_hash_search },
                        (draft) => {
                            let indexTicket = -1
                            const indexOrder = draft.data.orders.findIndex(
                                (order) => {
                                    const index = order.tickets.findIndex(ticket => ticket.id === id)
                                    if (index !== -1) {
                                        indexTicket = index
                                    }
                                    return index !== -1
                                }
                            )
                            if (indexOrder !== -1) {
                                draft.data.orders[indexOrder].tickets[indexTicket].first_name = first_name
                                draft.data.orders[indexOrder].tickets[indexTicket].last_name = last_name
                                draft.data.orders[indexOrder].tickets[indexTicket].email = email
                                draft.data.orders[indexOrder].tickets[indexTicket].ticket_holder = `${first_name} ${last_name}`
                            }
                        }
                    )
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
        refundTicketOrAddon: build.mutation<
            RefundTicketOrAddonResponse,
            {
                order_id: string
                order_hash: string
                type: 'ticket' | 'add-on'
                ticket_hash: string
                order_add_on_id: string
                refund_amount: string
                cancel_after_refund: boolean
                refund_type: 'custom_amount' | 'face_value' | 'full_amount'
                ticket_add_on_id: string
                withhold_application_fee: boolean
                email_search: string
                order_id_search: string
                ticket_hash_search: string
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error refunding ticket!", response),
            query: ({
                order_id,
                order_hash,
                type,
                ticket_hash,
                order_add_on_id,
                refund_amount,
                cancel_after_refund,
                refund_type,
                ticket_add_on_id,
                withhold_application_fee,
            }) => ({
                method: "POST",
                url: `/ticket/partial-refund`,
                body: {
                    data: {
                        attributes: {
                            order_id,
                            ticket_hash,
                            order_hash,
                            refund_type,
                            type,
                            order_add_on_id,
                            refund_amount,
                            cancel_after_refund,
                            ticket_add_on_id,
                            withhold_application_fee,
                        }
                    }
                }
            }),
            async onQueryStarted({
                ticket_hash_search,
                order_id_search,
                email_search,
                order_id,
            }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    const response: SupportToolsResponse = await (await fetch(
                        `${ENV.BUSINESS_CONSUMER}/api/support-tools-orders?order_id=${order_id}`,
                        {
                            method: "GET",
                            credentials: 'include',
                            headers: {
                                "Accept": "application/vnd.api+json",
                                "Content-Type": "application/vnd.api+json",
                                'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
                            }
                        }
                    )).json()
                    dispatch(
                        ordersApi.util.updateQueryData(
                            "getOrders",
                            { email: email_search, order_id: order_id_search, ticket_hash: ticket_hash_search },
                            (draft) => {
                                const indexOrder = draft.data.orders.findIndex(
                                    (order) => order.id === order_id
                                )
                                if (indexOrder !== -1) {
                                    const [order] = response.data.orders
                                    draft.data.orders[indexOrder] = order
                                }
                            }
                        )
                    )
                } catch { }
            },
        }),
        addNote: build.mutation<
            AddNoteOrderResponse,
            {
                note: string
                order_id: string
                order_id_search: string
                ticket_hash_search: string
                email_search: string
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when adding note order!", response),
            query: ({ note, order_id }) => ({
                method: "POST",
                url: `/order/${order_id}/notes`,
                body: {
                    data: {
                        attributes: {
                            note
                        }
                    }
                }
            }),
            async onQueryStarted({
                order_id,
                order_id_search,
                ticket_hash_search,
                email_search,
            }, { dispatch, queryFulfilled }) {
                try {
                    const response = await queryFulfilled
                    dispatch(
                        ordersApi.util.updateQueryData(
                            "getOrders",
                            { email: email_search, order_id: order_id_search, ticket_hash: ticket_hash_search },
                            (draft) => {
                                const indexOrder = draft.data.orders.findIndex(
                                    (order) => order.id === order_id,
                                )
                                if (indexOrder !== -1) {
                                    const date = dayjs().format("YYYY-MM-DD HH:mm:ss")
                                    draft.data.orders[indexOrder].notes.unshift({
                                        id: String(response.data.data.id),
                                        note: response.data.data.attributes.note,
                                        created_at: date,
                                        updated_at: '',
                                        name: response.data.data.attributes.name,
                                        email: response.data.data.attributes.email,
                                        can_edit_note: true
                                    })
                                }
                            }
                        )
                    )
                } catch { }
            },
        }),
        editNote: build.mutation<
            string,
            {
                order_id: string
                note_id: string
                note: string
                order_id_search: string
                ticket_hash_search: string
                email_search: string
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when editing note order!", response),
            query: ({ note_id, note }) => ({
                method: "PUT",
                url: `/order/note/${note_id}`,
                body: {
                    data: {
                        attributes: {
                            note,
                        }
                    }
                }
            }),
            async onQueryStarted({
                order_id,
                note,
                note_id,
                order_id_search,
                ticket_hash_search,
                email_search,
            }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    ordersApi.util.updateQueryData(
                        "getOrders",
                        { email: email_search, order_id: order_id_search, ticket_hash: ticket_hash_search },
                        (draft) => {
                            const indexOrder = draft.data.orders.findIndex(
                                (order) => order.id === order_id,
                            )
                            if (indexOrder !== -1) {
                                const indexNote = draft.data.orders[indexOrder].notes.findIndex(
                                    (note) => note.id === note_id,
                                )
                                if (indexNote !== -1) {
                                    const date = dayjs().format("YYYY-MM-DD HH:mm:ss")
                                    draft.data.orders[indexOrder].notes[indexNote].note = note
                                    draft.data.orders[indexOrder].notes[indexNote].created_at = date
                                    draft.data.orders[indexOrder].notes[indexNote].updated_at = date
                                }
                            }
                        }
                    )
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
        deleteNote: build.mutation<
            string,
            {
                order_id: string
                note_id: string
                order_id_search: string
                ticket_hash_search: string
                email_search: string
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when deleting note order!", response),
            query: ({ note_id }) => ({
                method: "DELETE",
                url: `/order/note/${note_id}`,
            }),
            async onQueryStarted({
                order_id,
                note_id,
                order_id_search,
                ticket_hash_search,
                email_search,
            }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    ordersApi.util.updateQueryData(
                        "getOrders",
                        { email: email_search, order_id: order_id_search, ticket_hash: ticket_hash_search },
                        (draft) => {
                            const indexOrder = draft.data.orders.findIndex(
                                (order) => order.id === order_id,
                            )
                            if (indexOrder !== -1) {
                                const indexNote = draft.data.orders[indexOrder].notes.findIndex(
                                    (note) => note.id === note_id,
                                )
                                if (indexNote !== -1) {
                                    draft.data.orders[indexOrder].notes.splice(indexNote, 1)
                                }
                            }
                        }
                    )
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
        assignAddOns: build.mutation<
            string,
            {
                order_id: string
                order_hash: string
                order_id_search: string
                ticket_hash_search: string
                email_search: string
                add_ons: [{
                    order_add_on_id: string
                    ticket_id: string
                    number: number
                }[]]
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when deleting note order!", response),
            query: ({ order_id, order_hash, add_ons }) => ({
                method: "PATCH",
                url: `/order/assign-add-ons/${order_id}`,
                body: {
                    data: {
                        attributes: {
                            add_ons,
                            order_hash,
                        }
                    }
                }
            }),
            async onQueryStarted({
                order_id,
                order_id_search,
                ticket_hash_search,
                email_search
            }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    const response: SupportToolsResponse = await (await fetch(
                        `${ENV.BUSINESS_CONSUMER}/api/support-tools-orders?order_id=${order_id}`,
                        {
                            method: "GET",
                            credentials: 'include',
                            headers: {
                                "Accept": "application/vnd.api+json",
                                "Content-Type": "application/vnd.api+json",
                                'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
                            }
                        }
                    )).json()
                    dispatch(
                        ordersApi.util.updateQueryData(
                            "getOrders",
                            { email: email_search, order_id: order_id_search, ticket_hash: ticket_hash_search },
                            (draft) => {
                                const indexOrder = draft.data.orders.findIndex(
                                    (order) => order.id === order_id
                                )
                                if (indexOrder !== -1) {
                                    const [order] = response.data.orders
                                    draft.data.orders[indexOrder] = order
                                }
                            }
                        )
                    )
                } catch { }
            },
        }),
    }),
})

export const ordersApiConsumer = createApi({
    reducerPath: "ordersApiConsumer",
    baseQuery: fetchBaseQuery({
        baseUrl: `${ENV.API_CONSUMER}/`,
        credentials: "include",
        headers: {
            "Accept": "application/vnd.api+json",
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
        },
    }),
    tagTypes: ["OrdersConsumer"],
    endpoints: (build) => ({
        cancelPaymentPlan: build.mutation<
            SupportToolsResponse,
            {
                orderId: string
                order_id?: string
                ticket_hash?: string
                email?: string
            }
        >({
            query: ({ orderId }) => ({
                method: 'POST',
                url: "/order/payment-plan/cancel",
                body: `orderId=${orderId}`
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when cancelling payment plan!", response),
            async onQueryStarted({
                orderId,
                order_id,
                ticket_hash,
                email,
            }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    ordersApi.util.updateQueryData(
                        "getOrders",
                        { email, order_id, ticket_hash },
                        (draft) => {
                            const indexOrder = draft.data.orders.findIndex(
                                (order) => order.id === orderId
                            )
                            if (indexOrder !== -1) {
                                draft.data.orders[indexOrder].payment_plan_can_be_cancelled = false
                            }
                        }
                    )
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
    }),
})

export const {
    useGetOrdersQuery,
    useResendOrderMutation,
    useRefundOrderMutation,
    useEditTicketMutation,
    useRefundTicketOrAddonMutation,
    useAddNoteMutation,
    useDeleteNoteMutation,
    useEditNoteMutation,
    useAssignAddOnsMutation,
} = ordersApi

export const {
    useCancelPaymentPlanMutation,
} = ordersApiConsumer
